<template>
	<Toolbar @clickLeft="$router.push('/home')" @clickRight="$router.push('/search')">
		<template v-slot:start>
			<Icon class="h-5 w-5" icon="angleLeft" />
		</template>
		<p>{{ category.name[currentLang] }}</p>
		<template v-slot:end>
			<Icon class="h-5 w-5" icon="magnifyingGlass" />
		</template>
	</Toolbar>
	<div class="grid grid-cols-2 gap-5 bg-light-blue p-5 pb-20">
		<div v-for="product in category.products" :key="product.id" class="relative rounded-md bg-white">
			<div :id="product.id" class="absolute -top-52 w-full"></div>
			<div v-if="product.isSoldOut"
				class="absolute z-30 inline-flex h-full w-full items-center justify-center rounded-md bg-secondary bg-opacity-60 text-3xl text-white">
				售罄</div>

			<!-- My favorite  -->
			<div v-if="inWishlist(product.id) >= 0" class="absolute top-1 right-1 h-7 w-7 text-red-500">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd"
						d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
						clip-rule="evenodd" />
				</svg>
			</div>

			<!-- 商品資料 -->
			<div class="flex h-full flex-col justify-between rounded-md bg-white" @click="setProduct(product)">
				<div class="space-y-2">
					<img class="h-40 w-full rounded-t-md object-cover" :src="product.imageUrls[0]" alt=""
						loading="lazy" />
					<div class="px-2">
						<!-- <p class="text-xs text-gray-400">{{ product.sku }}</p> -->
						<p class="text-sm font-bold">{{ product.name[currentLang] }}</p>
					</div>
				</div>

				<div class="text-md relative p-2 font-bold">
					<div class="relative">
						<!-- 切換價錢 -->
						<p v-if="product.variants[0].isCustomPrice" class="text-primary">{{
							$t('categories.quoteSeparately')
						}}</p>
						<p v-else-if="product.variants[0].originalPrice[saleMethod] > product.variants[0].sellingPrice[saleMethod]"
							class="flex flex-col text-red-500">
							<del class="text-xs text-muted">HK ${{
								product.variants[0].originalPrice[saleMethod]
							}}</del>HK ${{ product.variants[0].sellingPrice[saleMethod] }}
						</p>
						<p v-else class="text-primary">HK ${{ product.variants[0].sellingPrice[saleMethod] }}</p>
						<!-- 已加入購物車數量 -->
						<div v-if="inCart(product.id)"
							class="absolute bottom-0 right-0 inline-flex h-6 w-6 items-center justify-center rounded-2xl bg-primary text-white">
							{{ inCart(product.id)?.product.orderedQuantity }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { nextTick, onMounted } from 'vue'
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useCustomerStore } from '@/store/customer'
import { useCatalogStore } from '@/store/catalog'
import { useCartStore } from '@/store/cart'
export default {
	setup() {
		// const route = useRoute()
		const router = useRouter()
		// const store = useStore()
		const { currentLang } = storeToRefs(useSharedStore())
		const { inWishlist } = useCustomerStore()

		const { category, product } = storeToRefs(useCatalogStore())

		const { currentProduct, saleMethod } = storeToRefs(useCartStore())
		const { setCurrentProduct, setCurrentVariant, inCart } = useCartStore()

		// const id = computed(() => route.params.id)
		// const category = computed(() => store.getters.category(id.value))

		onMounted(() => {
			// if (!id.value || !category.value) router.push('/home')
			scrollToProduct()
		})

		function setProduct(val) {
			product.value = val
			if (inCart(val.id)) {
				const { product, index } = inCart(val.id)
				currentProduct.value = product
				return router.push(`/products/${val.id}?index=${index}`)
			}

			setCurrentProduct(val)
			setCurrentVariant(val.variants[0])
			router.push(`/products/${val.id}`)
		}

		function scrollToProduct() {
			// console.log(currentProduct.value.id)
			const element = document.getElementById(currentProduct.value?.id)
			console.log(element)
			if (!element) return
			nextTick(() => {
				element.scrollIntoView({ behavior: 'auto', block: 'start' })
			})
		}

		// function scrollToTop() {
		// 	const element = document.getElementById('baseLayout')
		// 	nextTick(() => {
		// 		element.scrollIntoView({ behavior: 'auto', block: 'start' })
		// 	})
		// }

		// function setCurrentProduct(product) {
		// 	currentProduct.value = product
		// 	store.state.currentProduct = product.id
		// 	router.push(`/products/${product.id}`)
		// }

		// function inWishlist(id) {
		// 	return store.getters.inWishlist(id)
		// }

		// function cartQuantity(id) {
		// 	return store.getters.cartProductQuantity(id)
		// }

		return {
			currentLang,
			setProduct,
			inCart,
			// category,
			// setCurrentProduct,
			category,
			inWishlist,
			// cartQuantity,
			saleMethod,
		}
	},
}
</script>
